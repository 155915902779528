<template>
  <div id="contrated-list">
    <v-card>
      <v-card-title>請求書(一覧)</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="contract_id"
            placeholder="契約ID"
            :items="contractIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col> -->
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="customer_id"
            placeholder="取引先"
            :items="customerIds"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'contract-create', params: { query: this.$route.query } }"
          >
            <span>NEW</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4"
            @click="downloadCSV"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        ref="contracts"
        class="class-on-data-table elevation-1"
        :headers="headers"
        :items="tableList"
        item-key="cd_id"
        :search="searchQuery"
      >
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.cont_id`]="{ item }">
          {{ item.cont_id }}
        </template>
        <template v-slot:[`item.cont_weight`]="{ item }">
          {{
            item.cont_weight ? formatData(item.cont_weight) : '0'
          }}
        </template>
        <template v-slot:[`item.cont_amount`]="{ item }">
          {{ formatAmount(item.ccy_mark,item.cont_amount) }}
        </template>
        <template v-slot:[`item.cont_stat_desc`]="{ item }">
          <label>{{
            contractStatus(item)
          }}</label>
        </template>
        <template v-slot:[`item.quot_code`]="{ item }">
          <v-icon v-if="item.quot_code !== null" class="mr-2" @click="previewItem(item)">
            {{ icons.mdiFilePdfBox }}
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editItem(item.contract_id)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <contract-delete
              :icon="icons.mdiDelete"
              :contract-id="item.contract_id"
              class="py-2 pl-1"
              @deleted="loadData(false)"
              @cancel="loadData(false)"
            ></contract-delete>
          </div>
        </template>
        <template v-slot:[`body.append`]>
          <tr v-if="product_id">
            <td
              class="font-weight-bold"
              :colspan="5"
              style="background: #fafafa"
            >
              <label>{{ product_id }}の合計</label>
            </td>
            <td
              class="font-weight-bold"
              style="text-align: right; background: #fafafa"
            >
              <label>{{ sumWgt }}</label>
            </td>
            <td
              style="background: #fafafa"
              :colspan="3"
            ></td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import { mdiPencil, mdiDelete, mdiExportVariant, mdiCalendar, mdiMinus, mdiPlus, mdiFilePdfBox, } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'
import ContractDelete from './ContractDelete.vue'
/* eslint-disable */
export default {
  components: {
    ContractDelete,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiCalendar,
      mdiMinus,
      mdiPlus,
      mdiFilePdfBox,
    },
    dateEndMenu: '',
    dateStartMenu: '',
    contract_status: '',
    contractStatuses: [
      { text: '仮契約', value: '1' },
      { text: '契約済', value: '2' },
      { text: '出荷可', value: '3' },
      { text: '出荷済', value: '4' },
      { text: '取消', value: '9' },
    ],
    auctionIds: [],
    preorderIds: [],
    searchBar: {},
    searchQuery: '',
    product_id: '',
    contract_id: '',
    customer_id: '',
    startDate: '',
    endDate: '',
    isExpand: false,
    tableList: [],
    headers: [
      {
        text: 'ID.',
        value: 'cont_id',
        align: 'left',
        width: '7%',
        fixed: true,
      },
      {
        text: '契約番号',
        value: 'cont_no',
        align: 'left',
        width: '15%',
        fixed: true,
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '15%',
        fixed: true,
      },



      {
        text: '入金予定日',
        value: 'cont_edd',
        align: 'center',
        width: '10%',
        fixed: true,
      },

      {
        text: '契約重量(KG)',
        value: 'cont_weight',
        align: 'right',
        width: '12%',
        sortable: true,
        fixed: true,
      },
      {
        text: '契約金額',
        value: 'cont_amount',
        align: 'right',
        width: '10%',
        sortable: true,
        fixed: true,
      },

      {
        text: 'PDF',
        value: 'quot_code',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
      {
        text: '　',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '10%',
        fixed: true,
      },
    ],
  }),

  computed: {

    ...mapState('customerStore', ['customerIds']),
    ...mapState('contractStore', ['pelletContracts', 'f_contracts', 'contractIds','contractList']),
    sumWgt() {
      let sum = 0
      this.contractList.forEach(a => {
        if (a.product_id === this.product_id) {
          sum += a.contract_weight
        }
      })

      return sum.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    contractStatus() {
      return function (item) {
        const status = this.contractStatuses.find(cs => cs.value === item.contract_status.toString())

        return status.text ?? ""
      }
    },
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  watch: {

    customer_id(newValue) {
      this.changeRouteQuery('customer_id', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    contract_status(newValue) {
      this.changeRouteQuery('contract_status', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      if (this.product_id) {
        this.loadData(true)
      } else {
        this.loadData(false)
      }
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    // this.loadContractIds()
    // this.loadProductIds()
    // this.loadCustomerIds()
     this.loadData(false)
  },

  methods: {

    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('contractStore', ['loadPelletContracts','loadContracts']),
    ...mapMutations('app', ['setOverlayStatus']),

    previewItem(item)
    {
      console.log('view', item)
      this.$router.push({
        name: 'invoice-preview',
        params: {
          id: item.cont_id,
          // cust_id: item.cust_id,
        },
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      this.loadContracts(this.$route.query).then(() => {
        this.tableList = this.$deepCopy(this.contractList)
        this.setOverlayStatus(false)
      })
    },
    editItem(contractId) {

      this.$router.push({ name: 'contract-pellet-edit', params: { id: contractId, query: this.$route.query } })
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({ path: this.$route.path, query })
    },
    loadQuery() {
      this.contract_id = this.$route.query.contract_id ?? ''
      this.product_id = this.$route.query.product_id ?? ''
      this.customer_id = this.$route.query.customer_id ?? ''
      this.contract_status = this.$route.query.contract_status ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    downloadCSV() {

      // eslint-disable-next-line no-useless-concat
      let csv = '\ufeff' + 'No.,契約ID,契約日,取引先ID,契約名,ペレットID,重量(kg),契約単価,備考\n'
      let no = 0
      this.contractList.forEach(el => {
        no += 1
        const contract_id = "CP-" + el.contract_id +"-" + el.detail_id
        const contract_date = el.contract_date ?? ""
        const customer_id = el.customer_id ?? ""
        const contract_name = el.contract_name ?? ""
        const product_id = el.product_id ?? ""
        const contract_weight = el.contract_weight ? el.contract_weight.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const contract_price = el.contract_price ? el.contract_price.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : ""
        const note  = el.contract_note ?? ""
        const line = `"${no}","${contract_id}","${contract_date}","${customer_id}","${contract_name}","${product_id}","${contract_weight}","${contract_price}","${note}"\n`
        csv += line
      })
      const blob = new Blob([csv], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const nowstr = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 19)
      const newstr = nowstr.replace(/(-|:|T)/g, '')
      link.download = `ペレット契約一覧-${newstr}.csv`
      link.click()
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
tr {
  height: 50px;
  font-size: 14px;
}
td {
  border-bottom: thin solid rgba(94, 86, 105, 0.14);
  padding: 0px 16px;
}
</style>
